/* You can add global styles to this file, and also import other style files */
@import url("@scania/tegel/dist/tegel/tegel.css");
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.mainContainer {
  min-height: calc(100vh - 279px);
  padding-bottom: 16px;
  overflow-x:hidden
}

.tds-accordion-item {
  border-top: none !important;
}

.desktopOnly {
  display: block !important;
}

.mobileOnly {
  display: none !important;
}

body {
  /* overflow-x: hidden; */
  overflow-wrap: break-word;
}

.table-scroll {
  /* width: 100% !important; */
  overflow-x: auto !important;
}

.accordion-overflow {
  overflow: visible;
  width: 120%;
}

.sistable.table {
  border-collapse: collapse;
}
.sistable thead th {
  font: var(--tds-headline-07);
  letter-spacing: var(--tds-headline-07-ls);
  display: table-cell;
  text-align: left;

  border-bottom: 1px solid var(--tds-table-header-background-hover);
  padding: 0px;
  height: 48px;
  min-width: unset;
  box-sizing: border-box;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms ease 0s;
  padding: 8px 16px;
  margin: 0px;
}

.sistable tbody td {
  font: var(--tds-detail-02);
  letter-spacing: var(--tds-detail-02-ls);
  display: table-cell;
  box-sizing: border-box;
  color: var(--tds-table-color);
  padding: var(--tds-spacing-element-16) 4px;

  vertical-align: top;
  background-color: transparent;
  transition: background-color 200ms ease 0s;
}

.sistable tbody tr {
  box-sizing: border-box;
  display: table-row;
  border-bottom: 1px solid #cdd1db;
  background-color: var(--tds-table-body-row-background);
  transition: background-color 200ms ease 0s;
  color: var(--tds-table-color);
}

.sistable tbody tr:hover {
  background-color: var(--tds-table-body-row-background-hover);
}

.sistable.table-responsive {
  width: 100%;
}

@media (max-width: 800px) {
  .mainContainer {
    min-height: calc(100vh - 306px);
  }
}

@media (max-width: 992px) {
  .desktopOnly {
    display: none !important;
  }

  .mobileOnly {
    display: block !important;
  }
}
